import {
  Input,
  Output,
  Directive,
  OnChanges,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import {
  Pager,
  PagerData,
  PaginationService,
} from '../services/pagination/pagination.service';

@Directive( {
  selector: '[paginationInit], [paginationOption.click]'
} )
export class PaginationInit implements OnChanges {

  target: any;

  @Input( 'paginationInit' ) data: PagerData = new PagerData();
  @Output( 'paginationOption.click' ) click: EventEmitter < number > = new EventEmitter < number > ();

  constructor(
    private elimentRef: ElementRef,
    private paginationService: PaginationService
  ) {

    this.target = this.elimentRef.nativeElement;
  }

  ngOnChanges() {

    this.paginationService.init( new Pager( {

      index: this.data.index,
      click: this.click,
      target: this.target,
      pageInfo: this.data.pageInfo
    } ) );
  }
}