import {
  Config
} from '../../app.config';
import {
  Injectable,
  EventEmitter
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
declare var $: any;

@Injectable()
export class TranslatedService {

  public pull: any;

  private res: any;
  private translate: string = 'ca_translate';

  constructor(
    private translateService: TranslateService
  ) {

    // init
    this.res = {

      translateInfo: new TranslateInfo(),
      translateInfos: new Array < TranslateInfo > ( new TranslateInfo( 'ko', 'Korean' ), new TranslateInfo( 'en', 'English' ), new TranslateInfo( 'ja', 'Japanese' ) )
    }

    this.pull = {

      reload: new EventEmitter(),
      translate: new EventEmitter()
    }
  }

  // get
  getCookie( key ) {

    return document.cookie.match( '(^|;) ?' + key + '=([^;]*)(;|$)' ) || null;
  }

  get getTranslate() {

    return this.res.translateInfo;
  }

  get getTranslateList() {

    return this.res.translateInfos;
  }

  // set
  setCookie( key, value ) {

    document.cookie = `${ key }=${ value }; path=/`;
  }

  setTranslate( translateInfo: TranslateInfo = null ) {

    // check translate
    if ( translateInfo ) {

      // set translate
      this.res.translateInfo = translateInfo;

      // set cookie
      this.setCookie( this.translate, this.res.translateInfo.code );
    } else {

      // get cookie code
      var lang = this.translateService.getBrowserLang().toLowerCase();
      var code = this.getCookie( this.translate ) || lang;

      // get translate
      this.res.translateInfo = this.res.translateInfos.find( ( translateInfo: TranslateInfo ) => {

        if ( code.indexOf( translateInfo.code ) > -1 ) {

          return true;
        }
      } ) || new TranslateInfo( Config.translate.base.code, Config.translate.base.name );
    }

    // set translate
    this.translateService.use( this.res.translateInfo.code );
    this.translateService.setDefaultLang( this.res.translateInfo.code );

    // set event
    this.pull.translate.emit();
  }

  // http
  getTranslates() {

    return new Promise( ( resolve ) => {

      // check translate
      if ( this.getTranslateList.length > 0 ) {

        this.setTranslate();

        return resolve();
      }
    } );
  }
}

export class TranslateInfo {

  code: string = null; // 코드
  name: string = null; // 이름

  constructor( code ? : string, name ? : string ) {

    if ( code != undefined ) this.code = code;
    if ( name != undefined ) this.name = name;
  }
}