import {
  NgModule
} from '@angular/core';
import {
  HttpModule
} from '@angular/http';
import {
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  Routes,
  RouterModule
} from '@angular/router';
import {
  BrowserModule
} from '@angular/platform-browser';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import {
  TranslateHttpLoader
} from '@ngx-translate/http-loader'; // 언어팩 모듈 ( /web/client/src/assets/i18n/* )

// config
import {
  Config
} from './app.config'; // 설정 파일

// root
import {
  MainComponent
} from './components/main/main.component'; // 메인 화면 구성

// public
import {
  FooterComponent
} from './components/footer/footer.component'; // 메인 화면 하단
import {
  NavigationComponent
} from './components/navigation/navigation.component'; // 메인 화면 상단 
import {
  PaginationComponent
} from './components/pagination/pagination.component'; // 페이지 조회 기능 ( ex: 게시물 목록 하단 페이지 )

// directive
import {
  PaginationInit
} from './directive/pagination.directive'; // 페이지 조회 디렉티브
import {
  MaterializeInit
} from './directive/materialize.directive'; // Materializecss 디렉티브 ( web/client.real/angular.json - https://materializecss.com/ )

// service
import {
  NavigationService
} from './services/navigation/navigation.service'; // 메인 화면 상단 이벤트 처리
import {
  PaginationService
} from './services/pagination/pagination.service'; // 페이지 조회 이벤트 처리
import {
  TranslatedService
} from './services/translated/translated.service'; // 언어팩 이벤트 처리

// pipe
import {
  Trim,
  Join,
  Html,
  Explain,
  DateTime,
} from './pipe/pipe';

// guard
import {
  TranslateGuard
} from './guard/translate.guard'; // 언어 조회 및 언어팩 적용

// page
import {
  IndexComponent
} from './components/page/index/index.component'; // HOME
import {
  CompanyComponent
} from './components/page/company/company.component'; // COMPANY
import {
  BusinessComponent
} from './components/page/business/business.component'; // BUSINESS
import {
  NewsComponent
} from './components/page/news/news.component'; // NEWS
import {
  ContactComponent
} from './components/page/contact/contact.component'; // CONTACT

// set route
const routes: Routes = [ {
  path: Config.base.main.path,
  component: MainComponent,
  canActivate: [ TranslateGuard ],
  children: [ {
    path: Config.base.main.path,
    component: IndexComponent
  }, {
    path: Config.page.company.path,
    component: CompanyComponent
  }, {
    path: Config.page.business.path,
    component: BusinessComponent
  }, {
    path: Config.page.news.path,
    component: NewsComponent
  }, {
    path: Config.page.contact.path,
    component: ContactComponent
  } ]
} ];

// export translation
export function createTranslateLoader( httpClient: HttpClient ) {

  return new TranslateHttpLoader( httpClient, Config.translate.path, Config.translate.type );
}

@NgModule( {
  declarations: [
    MaterializeInit, PaginationInit, // directive
    Trim, Join, Html, Explain, DateTime, // pipe
    MainComponent, FooterComponent, NavigationComponent, PaginationComponent, // public
    IndexComponent, CompanyComponent, BusinessComponent, NewsComponent, ContactComponent // page
  ],
  providers: [
    TranslateGuard,
    PaginationService,
    NavigationService,
    TranslatedService
  ],
  imports: [
    HttpModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot( routes, {

      onSameUrlNavigation: 'reload' // 동일한 경로 이벤트 처리
    } ),
    TranslateModule.forRoot( {
      loader: {
        deps: [ HttpClient ],
        provide: TranslateLoader,
        useFactory: ( createTranslateLoader )
      }
    } )
  ],
  exports: [
    RouterModule,
    TranslateModule
  ]
} )
export class AppRoutingModule {}