import {
  Component,
  OnInit,
  ElementRef
} from '@angular/core';
import {
  Subscription
} from 'rxjs';
import {
  PAGE_TYPE,
  ARROW_TYPE
} from 'src/app/services/pagination/pagination.service';
import {
  Pager,
  PageInfo,
  PaginationService,
} from 'src/app/services/pagination/pagination.service';
import {
  Config
} from 'src/app/app.config';

@Component( {
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.css' ]
} )
export class PaginationComponent implements OnInit {

  // enum
  public PAGE_TYPE: PAGE_TYPE;
  public ARROW_TYPE: ARROW_TYPE;

  // private
  private pager: Pager;
  private subscription: Subscription;

  constructor(
    private element: ElementRef,
    private paginationService: PaginationService
  ) {

    // enum
    this.PAGE_TYPE = PAGE_TYPE.ARROW_NEC_NUM_OPT;

    // init
    this.pager = new Pager( {

      index: 1,
      pageInfo: new PageInfo()
    } );
  }

  ngOnInit() {

    // subscript
    this.subscription = this.paginationService.push.page.subscribe( ( data: Pager ) => {

      // check same element
      if ( this.element.nativeElement == data.target ) {

        // set data
        this.pager = data;
      }
    } );
  }

  ngOnDestory() {

    this.subscription.unsubscribe();
  }

  get getIndex() {

    return this.pager.index / Config.limit + 1;
  }
  get getPages() {

    // min, max
    var max = 1;
    var min = 1;
    var arr = new Array();

    switch ( this.PAGE_TYPE ) {

      case PAGE_TYPE.ARROW_NEC_NUM_NEC:
      case PAGE_TYPE.ARROW_OPT_NUM_NEC:
        min = ( Math.floor( ( this.getIndex - 1 ) / Config.range ) * Config.range ) + 1;
        max = ( ( Math.floor( ( this.getIndex - 1 ) / Config.range ) + 1 ) * Config.range );
        break;

      case PAGE_TYPE.ARROW_NEC_NUM_OPT:
      case PAGE_TYPE.ARROW_OPT_NUM_OPT:
        min = ( Math.floor( ( this.getIndex - 1 ) / Config.range ) * Config.range ) + 1;
        max = ( ( Math.floor( ( this.getIndex - 1 ) / Config.range ) + 1 ) * Config.range );
        max = Math.max( Math.min( max, Math.ceil( this.pager.pageInfo.maximum ) ), 1 );
        break;

      case PAGE_TYPE.ARROW_NONE_CENTER:
        max = Math.max( Math.min( this.getIndex + Math.ceil( Config.range / 2 ), Math.ceil( this.pager.pageInfo.maximum ) ), 1 );
        min = Math.max( max - Config.range, 1 );
        break;

      default:
        break;
    }

    // set array
    for ( var idx = min; idx < max + 1; idx++ ) {

      arr.push( idx );
    }

    return arr;
  }

  // 전체 페이지 갯수 
  get getIsShowArrowPrev() {

    switch ( this.PAGE_TYPE ) {

      case PAGE_TYPE.ARROW_NEC_NUM_NEC:
      case PAGE_TYPE.ARROW_NEC_NUM_OPT:
        return true;

      case PAGE_TYPE.ARROW_OPT_NUM_NEC:
      case PAGE_TYPE.ARROW_OPT_NUM_OPT:
        return this.getIsMinArrow;

      default:
        return false;
    }
  }
  get getIsShowArrowNext() {

    switch ( this.PAGE_TYPE ) {

      case PAGE_TYPE.ARROW_NEC_NUM_NEC:
      case PAGE_TYPE.ARROW_NEC_NUM_OPT:
        return true;

      case PAGE_TYPE.ARROW_OPT_NUM_NEC:
      case PAGE_TYPE.ARROW_OPT_NUM_OPT:
        return this.getIsMaxArrow;

      default:
        return false;
    }
  }

  get getIsMinArrow() {

    var min = ( Math.floor( ( this.getIndex - 1 ) / Config.range ) * Config.range ) + 1;

    return min > 1;
  }
  get getIsMaxArrow() {

    var max = ( ( Math.floor( ( this.getIndex - 1 ) / Config.range ) + 1 ) * Config.range );

    return max < Math.ceil( this.pager.pageInfo.maximum );
  }

  getIsMaxIndex( index: number ) {

    return index <= Math.ceil( this.pager.pageInfo.maximum );
  }

  onPrev() {

    if ( !this.getIsMinArrow ) {

      return;
    }

    var beforemax = ( ( Math.floor( ( this.getIndex - 1 ) / Config.range ) ) * Config.range );

    this.setIndex( beforemax );
  }

  onNext() {

    if ( !this.getIsMaxArrow ) {

      return;
    }
    var aftermin = ( Math.floor( ( this.getIndex - 1 ) / Config.range ) * Config.range ) + Config.range + 1;

    this.setIndex( aftermin );
  }

  setIndex( index: number ) {

    if ( !this.getIsMaxIndex( index ) ) {

      return;
    }

    // set page index 
    this.pager.index = Config.limit * ( index - 1 );

    // event click
    this.pager.click.emit( index );
  }
}