import {
  NgModule
} from '@angular/core';

import {
  AppRoutingModule
} from './app.routing.module';

import {
  PreloaderService
} from './services/preloader/preloader.service';

import {
  AppComponent
} from './components/root/app.component';

import {
  PreloaderComponent
} from './components/preloader/preloader.component'; // 로딩 UI

import './prototypes/number'; // Number 확장 함수
import {
  DatePipe
} from '@angular/common';

import {
  COMPOSITION_BUFFER_MODE
} from '@angular/forms';

@NgModule( {
  imports: [
    AppRoutingModule
  ],
  exports: [],
  providers: [
    DatePipe,
    PreloaderService,
    {
      provide: COMPOSITION_BUFFER_MODE, // 한글 입력시 마지막이 잘리는 문제로 버퍼 설정 변경
      useValue: false
    }
  ],
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent,
    PreloaderComponent
  ]
} )

export class AppModule {}