export let Config = {
  base: {
    main: {
      path: ''
    },
    index: {
      path: '/'
    }
  },
  page: {
    news: {
      path: 'news',
      route: null
    },
    company: {
      path: 'company',
      route: null
    },
    contact: {
      path: 'contact',
      route: null
    },
    business: {
      path: 'business/:path',
      route: [ 'business/management' ]
    }
  },
  range: 5,
  index: 0,
  limit: 20,
  translate: {
    type: '.json',
    path: './assets/i18n/',
    base: {
      code: 'en',
      name: 'English'
    }
  }
}

export let Navigation = {

  business: {
    path: Config.page.business,
    index: 0
  },
  company: {
    path: Config.page.company,
    index: 1
  },
  /* news: {
    path: Config.page.news,
    index: 2
  }, */
  contact: {
    path: Config.page.contact,
    index: 2
  }
}