import {
  OnInit,
  Component
} from '@angular/core';
import {
  Router,
  NavigationEnd
} from '@angular/router';
import {
  NavigationItem,
  NavigationService,
} from 'src/app/services/navigation/navigation.service'
import {
  TranslateInfo,
  TranslatedService
} from 'src/app/services/translated/translated.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: [ './navigation.component.css' ]
} )

export class NavigationComponent implements OnInit {

  // init
  reload: string;
  subscription: any;
  navigationItems: NavigationItem[];

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private translatedService: TranslatedService
  ) {

    // navigation
    this.navigationItems = new Array < NavigationItem > ();

    // subscript 
    this.subscription = {

      router: this.router.events.subscribe( ( event: NavigationEnd ) => {

        if ( ( event instanceof NavigationEnd ) == false ) {

          return;
        }

        /* google analitics */
        // ( < any > window ).gtag( 'config', 'UA-135030035-1', event.urlAfterRedirects );

        // check reload
        if (
          this.reload &&
          this.reload.indexOf( event.url ) > -1
        ) {

          // reload
          this.translatedService.pull.reload.emit();
        } else {

          // set reload
          this.reload = event.url;

          // set router
          this.setRouter( event.url );
        }
      } ),
      navigation: this.navigationService.navigationLoaded.subscribe( ( url ) => {

        // set navigation
        this.navigationItems = this.navigationService.getNavigations;

        // set router
        this.setRouter( url || this.router.url );
      } )
    }
  }

  ngOnInit() {

    // init
    this.navigationService.getSession();
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  // get
  getActive( index: number ) {

    if ( index > -1 ) {

      return this.navigationService.getIndex == index;
    }

    return false;
  }

  // set
  setRouter( url: string ) {

    // scroll top
    window.scrollTo( 0, 0 );

    // return router
    return this.navigationItems.some( ( navigationItem: NavigationItem, navigationIndex: number ) => {

      var _url = url.split( '/' )[ 1 ];

      if ( navigationItem.router[ 0 ].indexOf( _url ) > -1 && _url.length > 0 ) {

        this.navigationService.setIndex( navigationIndex );

        return true;
      }

      this.navigationService.setIndex( -1 );

      return false;
    } );
  }

  get getTranslateList() {

    return this.translatedService.getTranslateList;
  }

  // action
  onNavigation( index: number ) {

    try {

      var instance = M.Sidenav.getInstance( $( '.sidenav' ) );

      instance.close();
    } catch ( error ) {}

    // check home
    this.navigationService.setIndex( index );

    // navigate router
    this.router.navigate( this.navigationService.getRoute );
  }

  // action 
  onTranslate( translateInfo: TranslateInfo ) {

    try {

      var instance = M.Sidenav.getInstance( $( '.sidenav' ) );

      instance.close();
    } catch ( error ) {}

    this.translatedService.setTranslate( translateInfo );
  }
}