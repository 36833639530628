import {
  Injectable,
  EventEmitter
} from '@angular/core';
import {
  Navigation, Config
} from 'src/app/app.config';

export class NavigationItem {

  constructor( public navigate: string, public router: any, public index: number ) {}
}

@Injectable()
export class NavigationService {

  // init
  private index: number;
  private navigations: Array < NavigationItem > ;

  public navigationLoaded: EventEmitter < any > ;

  constructor() {

    // init
    this.index = -1;

    this.navigations = new Array < NavigationItem > ();
    this.navigationLoaded = new EventEmitter();
  }

  // check
  get isMobile() {

    return /iphone|ipad|ipod|android/i.test( navigator.userAgent.toLowerCase() );
  }

  // get
  get getRoute() {

    if ( this.index > -1 ) {

      return this.navigations[ this.index ].router;
    }

    return [ Config.base.index.path ];
  }

  get getIndex() {

    return this.index;
  }

  get getNavigations(): Array < NavigationItem > {

    return this.navigations;
  }

  // set
  setIndex( index: number = 0 ) {

    this.index = index;
  }

  // http
  getSession( url: string = null ) {

    // init 
    this.navigations = new Array < NavigationItem > ();

    // navigate
    for ( let key in Navigation ) {

      // puch navigation
      this.navigations.push( new NavigationItem( key, Navigation[ key ].path.route || [ Navigation[ key ].path.path ], Navigation[ key ].index ) );
    }

    this.navigationLoaded.emit( url );
  }
}