import {
  Injectable
} from '@angular/core';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

import {
  TranslatedService
} from '../services/translated/translated.service';

@Injectable()
export class TranslateGuard implements CanActivate {

  constructor(
    private translatedService: TranslatedService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise < boolean > {

    return new Promise( ( resolve ) => {

      Promise.resolve().then( () => {

        // get translate
        return this.translatedService.getTranslates();
      } ).then( () => {

        resolve( true );
      } );
    } );
  }
}