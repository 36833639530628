import {
  Input,
  Output,
  Directive,
  OnChanges,
  ElementRef,
  EventEmitter
} from "@angular/core";

declare var $: any;
declare var M: any;

export class MaterializeClose {

  target: any;
  update: boolean;

  constructor( target: any, update: boolean = false ) {

    this.target = target;
    this.update = update;
  }
}

@Directive( {
  selector: '[materializeInit], [materializeOption.any], [materializeOption.data], [materializeOption.time], [materializeOption.date], [materializeOption.close], [materializeOption.cycle], [materializeOption.resize], [materializeOption.counter], [materializeOption.opacity]'
} )
export class MaterializeInit implements OnChanges {

  private _init: any;
  private _element: any;
  private _subscript: MutationObserver;

  // input
  @Input() materializeInit: string;
  @Input( 'materializeOption.any' ) any: any;
  @Input( 'materializeOption.data' ) data: string;
  @Input( 'materializeOption.resize' ) resize: boolean = false;
  @Input( 'materializeOption.counter' ) counter: boolean = false;
  @Input( 'materializeOption.opacity' ) opacity: number = 0.5;

  // output
  @Output( 'materializeOption.time' ) time: EventEmitter < Function > = new EventEmitter < Function > ();
  @Output( 'materializeOption.date' ) date: EventEmitter < Function > = new EventEmitter < Function > ();
  @Output( 'materializeOption.cycle' ) cycle: EventEmitter < Function > = new EventEmitter < Function > ();
  @Output( 'materializeOption.close' ) close: EventEmitter < MaterializeClose > = new EventEmitter < MaterializeClose > ();

  constructor( private elementRef: ElementRef ) {

    // set init
    this._init = {

      tabs: false,
      carousel: false
    }

    // get element
    this._element = this.elementRef.nativeElement;

    // set subscript 
    this._subscript = new MutationObserver( () => {

      // check name
      if (
        ( this.materializeInit == 'tabs' ) ||
        ( this.materializeInit == 'select' ) ||
        ( this.materializeInit == 'carousel' ) ||
        ( this.materializeInit == 'dropdown' )
      ) {

        // init materialize
        setTimeout( () => {

          this.ngOnMaterialize();
        }, 100 );
      }
    } );

    // set observe
    this._subscript.observe( this._element, {

      childList: true
    } );
  }

  ngDoCheck() {

    // update text
    try {

      if ( M && M.updateTextFields ) {

        M.updateTextFields();
      }
    } catch ( error ) {

      return;
    }

    // update carousel
    if ( this._init.carousel ) {

      // set height
      var height = $( this._element ).find( 'img' ).height();
      var current = $( this._element ).height();

      if ( height != current ) {

        $( this._element ).height( height );
      }
    }
  }

  ngOnChanges() {

    // check name
    if ( this.materializeInit == 'textarea' ) {

      // init textarea
      setTimeout( () => {

        this.ngOnMaterialize();
      }, 100 );
    } else if ( 
      this.materializeInit == 'tabs' ||
      this.materializeInit == 'carousel' 
    ) {

      // init carousel
      if ( this._init[ this.materializeInit ] ) {

        this._init[ this.materializeInit ] = false;
      }
    }
  }

  ngOnDestroy() {

    this._subscript.disconnect();
  }

  ngAfterViewInit() {

    setTimeout( () => {

      this.ngOnMaterialize();
    }, 100 );
  }

  ngOnMaterialize() {

    switch ( this.materializeInit ) {

      case 'tabs':
        {

          this.onTabs();
          break;
        }
      case 'modal':
        {

          this.onModal();
          break;
        }
      case 'select':
        {

          this.onSelect();
          break;
        }
      case 'sidenav':
        {

          this.onSideNav();
          break;
        }
      case 'parallax':
        {

          this.onParallax();
          break;
        }
      case 'tooltips':
        {

          this.onTooltips();
          break;
        }
      case 'dropdown':
        {

          this.onDropDown();
          break;
        }
      case 'textarea':
        {

          // find textarea
          $( this._element ).find( 'textarea' ).each( ( index: number, target: any ) => {

            this.onTextArea( target );
          } );
          break;
        }
      case 'carousel':
        {

          this.onCarousel();
          break;
        }
      case 'datepicker':
        {

          this.onDatePicker();
          break;
        }
      case 'timepicker':
        {

          this.onTimePicker();
          break;
        }
    }
  }

  // set
  setDestroy( element: string ) {

    // get instance
    var instance = M[ element ].getInstance( this._element );

    // check instance
    if ( instance ) {

      // set destroy
      instance.destroy();
    }
  }

  // action
  onTabs() {

    // check init
    if ( this._init.tabs ) {

      return;
    }

    // check destroy 
    this.setDestroy( 'Tabs' );

    // init tabs 
    M.Tabs.init( this._element );

    setTimeout( () => {

      this._element.scrollTo( 0, 0 );
    }, 100 );

    // set init
    this._init.tabs = true;
  }

  onModal() {

    // init modal
    M.Modal.init( this._element, {

      opacity: this.opacity,

      onOpenEnd: () => {

        // update text
        if ( M && M.updateTextFields ) {

          M.updateTextFields();
        }

        // init select
        $( this._element ).find( 'select' ).each( function() {

          var instance = M.FormSelect.getInstance( this );

          if ( instance ) {

            instance.destroy();
          }

          M.FormSelect.init( this );
        } );

        // init tabs
        var tabs = $( this._element ).find( '.tabs' );

        if ( tabs.length > 0 ) {

          M.Tabs.getInstance( tabs ).updateTabIndicator();
        }
      }
    } );
  }

  onSelect() {

    // check destroy 
    this.setDestroy( 'FormSelect' );

    // select
    M.FormSelect.init( this._element );
  }

  onSideNav() {

    // sidenav
    M.Sidenav.init( this._element );
  }

  onParallax() {

    // check destroy
    this.setDestroy( 'Parallax' );

    // parallax
    M.Parallax.init( this._element, this.any );
  }

  onTooltips() {

    // check data
    if ( !this.data ) {

      return;
    }

    // tooltips
    M.Tooltip.init( this._element, {

      html: this.data
    } );
  }

  onCarousel() {

    // check length
    if ( this._init.carousel || $( this._element ).children().length.empty() ) {

      return;
    }

    // check destroy
    this.setDestroy( 'Carousel' );

    // remove indicators
    $( this._element ).find( '.indicators' ).remove();

    // carousel
    M.Carousel.init( this._element, {

      onCycleTo: ( event: any ) => {

        // check cycle
        if ( this.cycle ) {

          this.cycle.emit( event )
        }
      },
      fullWidth: true,
      indicators: true
    } );

    // set init
    this._init.carousel = true;
  }

  onDropDown() {

    try {

      // check destroy 
      this.setDestroy( 'Dropdown' );

      // init dropdown
      M.Dropdown.init( this._element, Object.assign( {

        onOpenStart: () => {

          $( this._element ).children( 'span' ).addClass( 'active' );
        },
        onCloseStart: () => {

          $( this._element ).children( 'span' ).removeClass( 'active' );
        }
      }, this.any ) );
    } catch ( error ) {}
  }

  onTextArea( target: any = this._element ) {

    // resize
    if ( this.resize ) {

      // set resize
      M.textareaAutoResize( target );
    }

    // set counter
    if ( this.counter ) {

      // check counter
      if ( $( target ).parent().find( '.character-counter' ).length.empty() ) {

        // set counter
        $( target ).characterCounter();
      }
    }
  }

  onDatePicker( format: string = 'yyyy-MM-dd' ) {

    // check destroy 
    this.setDestroy( 'Datepicker' );

    // set event
    this.date.emit( ( option: any ) => {

      // init datepicker
      M.Datepicker.init( this._element, Object.assign( option, {

        onClose: () => {

          this.close.emit( new MaterializeClose( this._element, true ) );
        }
      } ) );

      setTimeout( () => {

        // set change
        $( this._element ).on( 'change', () => {

          // set event
          this._element.dispatchEvent( new Event( 'input' ) );
        } );
      } );
    } );
  }

  onTimePicker( format: string = 'hh:mm aa' ) {

    // check destroy 
    this.setDestroy( 'Timepicker' );

    // init timepicker
    this.time.emit( ( option: any ) => {

      M.Timepicker.init( this._element, option );

      setTimeout( () => {

        // set change
        $( this._element ).on( 'change', () => {

          // set event
          this._element.dispatchEvent( new Event( 'input' ) );
        } );
      } );
    } );
  }
}