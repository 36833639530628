import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  Subscription
} from 'rxjs';
import {
  TranslateService
} from '@ngx-translate/core';

@Component( {
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: [ './business.component.css' ]
} )
export class BusinessComponent implements OnInit {

  private path: string;
  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {

    this.subscription = this.route.params.subscribe( ( params: Params ) => {

      // set path
      this.path = params.path || 'management';
    } );
  }

  ngOnInit() {}

  ngOnDestroy() {

    this.subscription.unsubscribe();
  }

  // get
  getOn( path: string ) {

    return this.path == path.replace( /\s/g, '' );
  }

  getPath( path: string ) {

    let key = `business.board.${ this.path }.${ path }`;
    let translate = this.translateService.instant( key );

    if ( key == translate ) {

      return new Array();
    }

    return translate;
  }
}