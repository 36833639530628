Number.prototype.time = time;
Number.prototype.empty = empty;

interface Number {
  
  time: typeof time;
  empty: typeof empty;
}

function empty() {

  return this == 0;
}

function time() {

  var sec = this % 60;
  var hour = this / 60;
  
  return `${ hour < 10 ? `0${ hour.toFixed() }` : hour.toFixed() }:${ sec < 10 ? `0${ sec.toFixed() }` : sec.toFixed() }`;
}