import {
  Component,
  OnInit
} from '@angular/core';

@Component( {
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: [ './news.component.css' ]
} )
export class NewsComponent implements OnInit {

  private index: number;

  constructor() {

    this.index = 0;
  }

  ngOnInit() {}

  // get 
  getSelect( index: number ) {

    return this.index == index;
  }

  // action
  onSelect( index: number ) {

    this.index = index;
  }
}