import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  SafeStyle,
  DomSanitizer,
} from '@angular/platform-browser';
import {
  DatePipe
} from '@angular/common';

@Pipe( {
  name: 'trim'
} )
export class Trim implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( value: string ): SafeStyle {

    if ( value ) {

      return value.replace( /\s/g, '' );
    }

    return null;
  }
}

@Pipe( {
  name: 'join'
} )
export class Join implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( value: any ): SafeStyle {

    if ( value ) {

      return this.sanitizer.bypassSecurityTrustHtml( value.join( '<br/>' ) );
    }

    return null;
  }
}

@Pipe( {
  name: 'html'
} )
export class Html implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( array: Array < any > ) {

    var html = new Array< string >();

    array.forEach( ( element: any ) => {

      var object: HTMLElement = document.createElement( element.html );
  
      switch ( element.html ) {
  
        case 'p':
          {
  
            if ( typeof element.value == 'string' ) {

              object.textContent = element.value;
            } else {

              object.innerHTML = element.value.join( '<br/>' );
            }
            break;
          }
        case 'img':
          {
  
            object.setAttribute( 'src', element.value );
            break;
          }
      }

      html.push( object.outerHTML );
    } );

    return html.join( '' );
  }
}


@Pipe( {
  name: 'explain'
} )
export class Explain implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( value: any ): SafeStyle {

    var html = new Array( "<ol>" );

    if ( value ) {

      // set item
      value.forEach( ( text: string ) => {

        html.push( `<li>${ text }</li>` );
      } );

      // set end
      html.push( "</ol>" );

      return this.sanitizer.bypassSecurityTrustHtml( html.join( '' ) );
    }

    return null;
  }
}

@Pipe( {
  name: 'datetime'
} )
export class DateTime implements PipeTransform {

  constructor( private pipe: DatePipe = new DatePipe( 'en-US' ) ) {}

  transform( date: any, format: string ) {

    if ( typeof date == 'string' ) {

      return this.pipe.transform( date.replace( ' ', 'T' ), format );
    } else {

      return this.pipe.transform( date, format );
    }
  }
}